<template>
	<div class="">
		<div class="page-content">
			<el-breadcrumb separator=">" style="position: absolute;margin-top: -4px;">
				<el-breadcrumb-item :to="{ path: '/index' }">Home</el-breadcrumb-item>
				<el-breadcrumb-item>Digests</el-breadcrumb-item>
			</el-breadcrumb>
			<div>
				<el-input v-model="paramMap.searchValue" placeholder="Search by Title, Author, Keyword or Doi"
					class="input-with-select" style="border: solid 1px #6b57a5;">
					<template #prepend>
						<el-select v-model="paramMap.searchType"
							style="width: 115px;background-color: #f3f7ff;color: #333333;margin-left: -21px;">
							<el-option label="Title" value="Title" />
							<el-option label="Author" value="Author" />
							<el-option label="Keyword" value="Keyword" />
							<el-option label="Doi" value="Doi" />
						</el-select>
					</template>
					<template #append>
						<el-button type="primary" @click="searchData()"
							style="border-radius: 0px;margin-left: -82px;margin-top: -20px;position: absolute;color: white; background: rgb(107, 87, 165);">

							<el-icon style="vertical-align: middle;">
								<Search />
							</el-icon>
							<span style="vertical-align: middle;"> Search
							</span>
						</el-button>
					</template>
				</el-input>
			</div>
			<div class="allLoading" v-if="!search">
				<div style="width: 300px;margin-right: 20px;">
					<div
						style="padding: 15px 0 15px 0;background-color:#6b57a5;color: white;font-size: 20px;font-weight: bold;text-align: center;">
						SUBJECTS</div>
					<ul
						style="list-style: none;padding: 5px;ine-height: 33px;line-height: 40px;background-color: #f3f9fc;margin-top: -5px;">
						<li @click="tagCheck(-1)" :class="-1 == tagIndex ? 'tagActive' : ''">All</li>
						<li v-for="(item,itemIntex) in subjects" :key="itemIntex" @click="tagCheck(itemIntex)"
							:class="itemIntex == tagIndex ? 'tagActive' : ''">{{item.subject.newSubject}}</li>
					</ul>
				</div>
				<div class="dataLoading" v-if="detailsList">
					<div style="background-color:#f3f9fc;padding: 15px 0 15px 10px;">
						<span style="color: #6b57a5;margin-right: 30px;">About Digests</span>
						<span style="margin-right: 30px;">News</span>
						<span>Criteria</span>
					</div>
					<div style="display: flex;flex-wrap: wrap;margin-left: 25px;">
						<el-card :body-style="{ padding: '5px' }" shadow="hover" v-for="(item,itemIntex) in subjectsAll"
							:key="itemIntex" style="width: 400px;margin-right: 25px;margin-top: 20px;">
							<el-image style="" :src="getImgUrl(item.subject)"></el-image>
							<div style="padding: 14px" class="monthList">
								<span v-if="item.month!=''" v-for="(item2,itemIntex2) in item.month" :key="itemIntex"
									@click="tagCheck2(itemIntex,itemIntex2)">{{item2}}</span>
								<span v-else>暂无数据</span>
							</div>
						</el-card>
					</div>
				</div>
				<div class="dataLoading" v-else>
					<div style="background-color:#f3f9fc;padding: 15px 0 15px 10px;">
						<span style="color: #6b57a5;margin-right: 30px;">About Digests</span>
						<span style="margin-right: 30px;">News</span>
						<span>Criteria</span>
					</div>
					<div style="padding: 14px" class="monthList">
						<span v-for="(item,itemIntex) in subjectsAll[0].month" :key="itemIntex"
							@click="tagCheck2(itemIntex)">{{item}}</span>

					</div>
					<el-card :body-style="{ padding: '5px' }" shadow="hover"
						style="width: 850px;margin-right: 25px;margin-top: 20px;">
						<el-image style="width: 840px;height: 130px;" :src="getImgUrl(paramMap)">
						</el-image>
						<div style="height: 35px;">
							<p>
								<span style="float: left;font-weight: bold;">List of Hot Papers:</span>
								<span style="float: right;font-size: 14px;">Editor’s Choice
									{{paramMap.month}}</span>
							</p>
						</div>
					</el-card>
					<div style="margin-top: 40px;" v-for="(item,index) in detailsListData" :key="index">
						<div style="display: flex;">
							<div style="width: 750px;">
								<div style="display: flex;height: 75px">
									<p style="margin-top: 1px;">
										<el-icon style="margin-right: 10px;">
											<Collection />
										</el-icon>
									</p>
									<el-link :underline='false' href="javascript:void(0)" class="three"
										@click="details(index)"
										style="font-weight: bold;text-align: justify;color: #333333;font-size: 18px;">
										{{item.ti}}
									</el-link>

								</div>
								<p style="float: left;color: #747474;margin-left: 26px;">
									{{item.so}}&nbsp;&nbsp;{{item.py}}
								</p>
								<!-- <p style="float: right;">
									<el-link :underline='false' href="javascript:void(0)"
										style="margin-right: 10px;color: #6b57a5;">
										<el-icon>
											<Download />
										</el-icon>Download PDF
									</el-link>
									<el-link :underline='false' href="javascript:void(0)" style="color: #6b57a5;">
										<el-icon style=" margin-right: 10px;">
											<Share />
										</el-icon>
										<span>Share</span>
									</el-link>
								</p> -->
							</div>
							<div style="width: 100px;padding-top: 20px;">
								<el-image style="width: 80px;height: 110px;margin-left: 70px;"
									:src="'http://www.sciencealerts.org/qkima/images/'+item.sn+'.jpg'">
								</el-image>
							</div>
						</div>
						<el-divider />
					</div>
					<div style="text-align: center;">
						<el-pagination layout="prev, pager, next" :total="paramMap.dataCount"
							@current-change="handleCurrentChange" />
					</div>
				</div>
			</div>
			<div v-else>
				<p>Search Results: We've found {{searchListData.length}} results for '<span
						style="color: #b40b18;">{{paramMap.searchType}}</span>'
					<el-link :underline='false' href='javascript:void(0)' @click="returnPage()"
						style="margin-left: 20px;font-size: 16px;color: #6b57a5;">RETURN</el-link>
				</p>
				<div style="display: flex;margin-top: 40px;" v-for="(searchItem,index) in searchListData" :key="index">
					<div style="width: 1050;margin-right: 50px;">
						<el-card shadow="hover">
							<p v-html="heightSearchValue(searchItem.ti)" class="searchTi" @click="details(index)"></p>
							<div>
								<p>
									<span style="color: #0a0204;font-weight: bold;">Authors:&nbsp;</span>
									<span style="color: #2d8dc5;font-family: OpenSans;"
										v-html="heightSearchValue(searchItem.af)"></span>
								</p>
							</div>
							<div>
								<p>
									<span style="color: #0a0204;font-weight: bold;"> DOI:&nbsp;</span>
									<span style="color: #000000;font-family: OpenSans;color: #2d8dc5;"
										v-html="heightSearchValue(searchItem.di)"></span>
								</p>

							</div>
							<div>
								<p>
									<span style="color: #0a0204;font-weight: bold;"> Keywords:&nbsp;</span>
									<span style="color: #2d8dc5;font-family: OpenSans;"
										v-html="heightSearchValue(searchItem.de)"></span>
								</p>
							</div>
							<div style="margin-bottom: 40px;">
								<p>
									<span style="color: #0a0204;font-weight: bold;"> Published:&nbsp;</span>
									<span style="color: #000000;font-family: OpenSans;"
										v-html="searchItem.py+'-'+searchItem.pd"></span>
								</p>

							</div>
							<div style="background-color: rgb(243, 249, 252);;padding: 10px;">
								<p>
									<span style="color: #0a0204;font-weight: bold;"> Abstract:&nbsp;</span>
									<span style="color: #000000;font-family: OpenSans;"
										v-html="heightSearchValue(searchItem.ab)">
									</span>
								</p>
							</div>
							<!-- <div>
								<p style="float: right;">
									<el-link :underline='false' href="javascript:void(0)"
										style="margin-right: 10px;color: #6b57a5;">
										<el-icon>
											<Download />
										</el-icon>Download PDF
									</el-link>
									<el-link :underline='false' href="javascript:void(0)" style="color: #6b57a5;">
										<el-icon style=" margin-right: 10px;">
											<Share />
										</el-icon>
										<span>Share</span>
									</el-link>
								</p>
							</div> -->
						</el-card>
					</div>
					<div style="">
						<div style="width: 100px;">
							<el-image style="width: 80px;height: 110px;"
								:src="'http://www.sciencealerts.org/qkima/images/'+searchItem.sn+'.jpg'">
							</el-image>
						</div>
					</div>
				</div>
				<!-- <div style="text-align: center;margin-top: 40px;">
					<el-pagination layout="prev, pager, next" :total="paramMap.dataCount"
						@current-change="handleCurrentChange" />
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				subjects: [],
				subjectsAll: [],
				search: false,
				detailsList: true,
				detailsListData: [],
				tagIndex: -1,
				tagIndex2: -1,
				paramMap: {
					dataCount: 0,
					page: 1,
					searchValue: '',
					searchType: 'Title',
					subject: "",
					month: "",
				},
				searchListData: [],
			};
		},

		methods: {
			details(index) {
				this.$router.push({
					name: "details",
					params: this.detailsListData[index]
				});
			},

			getImgUrl(subject) {
				return require('@/assets/images/' + subject.newSubject + '.jpg')
			},
			getSubjectAndMonth() {
				let _this = this
				$http.post("getSubjectAndMonth", this.paramMap, "dataLoading")
					.then(function(res) {
						_this.subjects = res.data
						_this.subjectsAll = res.data
					})
					.catch(function() {});
			},
			queryKeleData() {
				let _this = this
				$http.post("queryKeleData", this.paramMap, "dataLoading")
					.then(function(res) {
						_this.paramMap.dataCount = res.dataCount;
						_this.detailsListData = res.data;
					})
					.catch(function() {});
			},
			returnPage() {
				this.search = false
			},
			searchData() {
				let _this = this
				if (_this.paramMap.searchValue == null || _this.paramMap.searchValue == "") {
					return false
				}
				this.search = true
				$http.post("queryKeleData", this.paramMap, "allLoading")
					.then(function(res) {
						_this.searchListData = res.data;
						_this.detailsListData = res.data;
					})
					.catch(function() {});

			},
			heightSearchValue(value) {
				let replaceReg = new RegExp(this.paramMap.searchValue, 'ig')
				let replaceString =
					`<span style="background-color: #6b57a5;color: white;">${this.paramMap.searchValue}</span>`
				return value.replace(replaceReg, replaceString)
			},
			tagCheck(itemIntex) {
				if (itemIntex == -1) {
					this.tagIndex = itemIntex;
					this.detailsList = true
					this.subjectsAll = []
					this.subjectsAll = this.subjects
				} else {
					this.tagIndex = itemIntex;
					this.detailsList = true
					this.subjectsAll = []
					this.subjectsAll.push(this.subjects[itemIntex])
				}
			},
			tagCheck2(itemIntex, itemIntex2) {
				this.detailsList = false
				this.paramMap.subject = this.subjectsAll[itemIntex].subject.oldSubject
				this.paramMap.newSubject = this.subjectsAll[itemIntex].subject.newSubject
				this.paramMap.month = this.subjectsAll[itemIntex].month[itemIntex2]
				this.queryKeleData();
			},
			handleCurrentChange(currentPage) {
				this.paramMap.page = currentPage;
				this.queryKeleData();
			},
		},
		created() {
			this.getSubjectAndMonth()
			this.paramMap.searchValue = this.$route.params.searchValue
			this.paramMap.searchType = this.$route.params.searchType == undefined ? "Title" : this.$route.params.searchType
			this.searchData()
		},
		mounted() {
			if (this.$route.params.subject != null && this.$route.params.month != null) {
				this.detailsList = false
				this.paramMap.subject = this.$route.params.subject
				this.paramMap.newSubject = this.$route.params.newSubject
				this.paramMap.month = this.$route.params.month
				this.queryKeleData();
			}
		},

	};
</script>

<style lang="scss" scoped>
	.allLoading {
		display: flex;
		margin-top: 40px;
	}

	.dataLoading {
		width: 900px;
	}

	.input-with-select {
		margin-left: 594px;
		width: 50%;
	}

	.el-select-dropdown__item.selected {
		color: #6b57a5;
		font-weight: 700;
	}

	ul>li {
		cursor: pointer
	}

	ul>li:hover {
		color: #fe7568;
	}

	.tagActive {
		color: #fe7568;
	}

	.monthList span {
		margin-right: 10px;
	}

	.monthList span:hover {
		cursor: pointer;
		color: #fe7568;
	}

	.searchTi {
		cursor: pointer
	}

	.searchTi:hover {
		color: #fe7568;
	}
</style>
<style>
	.el-link--inner:hover {
		color: #fe7568;
	}
</style>
